/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const audio = new Audio("/boing.mp3");

export default function Beer(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(
        "/MethodBeer_Can_anim07.gltf"
    );
    const { actions } = useAnimations(animations, group);

    const playBounceAnimation = () => {
        audio.play();
        actions.Bounce.reset().play();
        actions.Bounce.loop = THREE.LoopOnce;
    }


    return (
        <group ref={group} onClick={()=>playBounceAnimation()} {...props} dispose={null}>
            <group name="Scene">
                <group name="Beer">
                    <primitive object={nodes.Bone} />
                    <skinnedMesh
                        name="Cylinder"
                        geometry={nodes.Cylinder.geometry}
                        material={materials.Can}
                        skeleton={nodes.Cylinder.skeleton}
                    />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/MethodBeer_Can_anim07.gltf");