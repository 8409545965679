import { Canvas } from '@react-three/fiber';
import React from "react";
import { OrbitControls, Stage, PerspectiveCamera, Loader} from "@react-three/drei";
import { Suspense } from "react";
import Beer from "./models/Beer";
import './App.css';

function App() {
    const ref = React.useRef()
    return (
      <div className="App">
          <Canvas shadows dpr={[1, 2]} style={{ background: "rgb(148 77 255)" }}>
              <Suspense fallback={null}>
                  <Stage controls={ref} preset="rembrandt" intensity={1}  environment="city">
                      false
                      <Beer  />
                      false
                  </Stage>
              </Suspense>
              <PerspectiveCamera
                  makeDefault
                  position={[500, 0.9, 1.8]}
                  zoom={0.8}
              />
              <OrbitControls enablePan={false} ref={ref} autoRotate />
          </Canvas>
          <Loader />
      </div>
  );
}

export default App;
